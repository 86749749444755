
.message {
    margin: 10px 0;
    max-width: 700px;
    padding: 10px;
    border-radius: 8px;
    position: relative;
}

.message.business {
    margin-left: auto;
    background-color: #dcf8c6;
}

.message.user {
    margin-right: auto;
    background-color: #fff;
}

.message.pending {
    background-color: #e0e0e0;
}

.message-content {
    display: block;
    margin-bottom: 5px;
    word-wrap: break-word;
}

.message-time {
    font-size: 0.75rem;
    color: #666;
    display: block;
    text-align: right;
}

.msg-usage {
    font-size: 8px;
}