.chat-wrapper {
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-width: 800px;
    margin: 0 auto;
    padding: 0px 20px;
    width: 500px;
}

.ws-chat-title {
    text-align: center;
}

.chat-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
}


.chat-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.take-over-btn {
    padding: 8px 16px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.take-over-btn.active {
    background-color: #ffcdd2;
}
.refresh-button {
    background-color: #99e6b5;
    width: 50%;
    margin: 0px 30px;
    margin-bottom: 10px;
    border: 0px;
    border-radius: 5px;
}
.take-over-btn:hover {
    background-color: #74aae1;
}

.input-container {
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
}

.chat-textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 40px;
    max-height: 120px;
}

textarea:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.send-btn {
    padding: 8px 20px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.send-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.send-btn:hover:not(:disabled) {
    background-color: #128c7e;
}


/* Add at the bottom of WebsocketChat.css */
@media (max-width: 768px) {
  .chat-wrapper {
    width: 100%;
    padding: 0 10px;
    margin-top: 50px; /* Make room for back button */
  }
  
  .chat-container {
    padding: 10px;
  }
}


/* Connection status styles */
.chat-wrapper.connected {
    background-color: rgba(0, 255, 0, 0.1);
    transition: background-color 0.3s ease;
}

.chat-wrapper.disconnected {
    background-color: rgba(255, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

/* Header styles */
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

/* Connection toggle button styles */
.connection-toggle {
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.connection-toggle.connected {
    background-color: #dc3545;
    color: white;
}

.connection-toggle.connected:hover {
    background-color: #c82333;
}

.connection-toggle.disconnected {
    background-color: #28a745;
    color: white;
}

.connection-toggle.disconnected:hover {
    background-color: #218838;
}