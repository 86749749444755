td {
    font-size: 1rem;
}

.line1 {
    background-color: var(--color3);
    margin: 1%;
    padding: 0 1%;
    border-radius: 10px;
}

.line1:hover {
    background-color: var(--color2);
    cursor: pointer;
}

