.calendar-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--color3);
    border-radius: 8px;
}

.calendar-checkbox-group {
    margin-bottom: 2rem;
}

.calendar-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color1);
    font-weight: 600;
    cursor: pointer;
}

.calendar-checkbox-label input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.calendar-form-group {
    margin-bottom: 1.5rem;
}

.calendar-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--color1);
    font-weight: 600;
}

.calendar-input,
.calendar-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--color1);
    border-radius: 4px;
    background-color: white;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.calendar-textarea {
    min-height: 100px;
    resize: vertical;
}

.calendar-input:focus,
.calendar-textarea:focus {
    outline: none;
    border-color: var(--color2);
}

.calendar-submit-button {
    width: 100%;
    padding: 1rem;
    background-color: var(--color2);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.calendar-submit-button:hover:not(:disabled) {
    background-color: var(--color1);
}

.calendar-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.calendar-input::placeholder,
.calendar-textarea::placeholder {
    color: #888;
}
/* .calendar-config {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--color3);
  border-radius: 8px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color1);
  font-weight: 600;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--color1);
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--color2);
}

button[type="submit"] {
  width: 100%;
  padding: 1rem;
  background-color: var(--color2);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: var(--color1);
}

::placeholder {
  color: #888;
} */
