* {
    box-sizing: content-box;  /* keep borders and padding inside origin sizes */
    font-family: rubik;
}

body {
    margin: 0;
}

.bbot-header-title{
    margin: 15px;
}
.navbar {    
    background-color:  var(--color1);   
    font-weight: bold;     
    color: white;        
    display: flex;    
    justify-content: space-between;  /* separate site-title from buttons  */    
    align-items: stretch;  /* full-height of the hover-background. try compare to "center" */
    padding: 0 20px;   /* padding left & right with 1rem = 16px */
    gap: 2rem;  /* gap between elements of the navbar (title and ul) */    
}

.navbar a span {
    color: inherit;  /* color as for the navbar */
    text-decoration: none;   /* remove underline from <a> links */                
    padding: 0.25rem;
    margin: 0 0.25rem;
    display: flex;   
    height: 100%;
    align-items: center; 
    font-size: large;
    
}
@media only screen and (max-width: 400px) {
    .navbar a span{
        font-size: 10px;
        padding: 0.1rem;
        margin: 0 0.1rem;
    }
}

.site-title {
    font-size: 2rem;
    color: var(--color2);
}

.navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 2rem;
    font-size: 1.5rem;    
}

.navbar a:hover {   
    color: var(--color2) !important;
    cursor: pointer;
}


.header-logo {
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
}