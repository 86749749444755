.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: #f0f2f5; /* WhatsApp background color */
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 2% auto; /* Center containers */
  max-height: 500px; /* Or any fixed height you prefer */  
}
.chat-header {
    background-color: #075e54; /* WhatsApp green */
    color: white;
    padding: 12px 16px;
    border-radius: 10px 10px 0 0;
    margin: -15px -15px 15px -15px;
  }
  .chat-title {
    font-size: 16px;
    font-weight: 500;
  }
.message {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  padding: 8px 12px;

  border-radius: 8px;
  margin-bottom: 4px;
  position: relative;

  font-family: Arial, sans-serif;
  font-size: 14px;
}

.messages-wrapper {
    flex: 1;
    overflow-y: auto;
    min-height: 300px;
    padding: 15px;
    /* Styling the scrollbar for WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
  }
  
.user-message {
  align-self: flex-end;
  background-color: #e7ffdb; /* WhatsApp light green */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  color: #000;
}

.bot-message {
  align-self: flex-start;
  background-color: #fff;  
  border: 1px solid #ccc;
  font-size: 13px;  
  color: #667781; /* WhatsApp time color */
  margin-top: 2px;
}

.message-line {
  display: block;
  -webkit-display: block;
  font-family: Arial, sans-serif;
}


.message-text {
  margin-bottom: 5px;
}

.message-time {
  font-size: 12px;
  color: #888;
  text-align: right;
}
