.dashboard-demo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    position: relative;
    overflow: hidden;
    height: 800px; /* Adjust height for consistency */
  }
  
  .dashboard-demo-frame {
    position: absolute;
    border: 5px solid #4caf50; /* Green frame */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    transform: scale(0.9);
  }
  
  .dashboard-demo-frame.active {
    opacity: 1;
    transform: scale(1);
  }
  
  .dashboard-demo-frame.inactive {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .dashboard-demo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  