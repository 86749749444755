.home-container {
    text-align: center;
    padding: 20px;
  }
  
  .home-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .home-image {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .home-description {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .home-features, .home-benefits, .home-cta {
    margin-bottom: 30px;
  }
  
  .home-features ul, .home-benefits ul {
    list-style: none;
    padding: 0;
  }
  
  .home-features li, .home-benefits li {
    margin-bottom: 10px;
  }
  
  .home-features li i, .home-benefits li i {
    margin-right: 10px;
  }
  
  .home-cta button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }