.chats-page {
    display: flex;
    height: 80vh;
}

.cahts-title {
    text-align: center;
}

.chats-list {
    width: 30%;
    max-width: 200px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    background-color: #f5f5f5;
    padding: 10px;
}

.chat-item {
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.chat-item:hover {
    background-color: #e0e0e0;
}

.chat-list-title{
    font-weight: 600 ;
}

.chat-item.active {
    /* background-color: #c8e6c9; */
    border-radius: 50px;
    border: green solid 5px;
    /* border-color: green; */
}

.chat-window {    
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.chat-placeholder {
    color: #888;
    font-size: 1.2em;
}

.chat-item.locked {
    background-color: red;
    color: white;
}

/* Add at the bottom of ChatPage.css */
@media (max-width: 768px) {
    .chats-page {
      position: relative;
    }
    
    .chats-list {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transition: transform 0.3s ease;
    }
    
    .chats-list.hidden {
      transform: translateX(-100%);
    }
    
    .chat-window {
      width: 100%;
    }
  }