
.chatbot-container {
    position: fixed;
    bottom: 2%; /* Adjust position for mobile */
    left: 5%;
    z-index: 1000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, sans-serif;
  }

.chatbot-toggle {
  background-color: #059669;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}

.chatbot-toggle-open {
  background-color: #ffcccc; /* Light red */
  color: #000;
}

.chatbot-toggle-closed {
  background-color: #ccffcc; /* Light green */
  color: #000;
}

.chatbot-toggle:hover {
  background-color: #047857;
}

.chatbot-window {
    position: fixed;
    bottom: 10%; /* Adjust for better visibility on mobile */
    left: 5%; /* Ensure the chatbot doesn't stick too far to the left */
    width: 90%; /* Makes it responsive on smaller screens */
    max-width: 400px; /* Maximum width for larger screens */
    height: 80%; /* Adjust height to fit the screen better */
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
/* .chatbot-window {
  position: fixed;
  bottom: 80px;
  left: 150px;
  width: 400px;
  height: 600px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
} */

.chatbot-header {
  background-color: #059669;
  color: white;
  padding: 16px;
}

.chatbot-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.chatbot-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #f9fafb;
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message-wrapper {
  display: flex;
  width: 100%;
}

.visitor-wrapper {
  justify-content: flex-end;
}

.bot-wrapper {
  justify-content: flex-start;
}

.message {
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 12px;
  word-wrap: break-word;
  line-height: 1.5;
  font-size: 14px;
}

.visitor-message {
  background-color: #059669;
  color: white;
  border-bottom-right-radius: 4px;
}

.bot-message {
  background-color: #e5e7eb;
  color: #1f2937;
  border-bottom-left-radius: 4px;
}

.chatbot-input-area {
  border-top: 1px solid #e5e7eb;
  padding: 16px;
  background-color: white;
}

.input-container {
  display: flex;
  gap: 8px;
}

.input-container input {
  flex: 1;
  padding: 8px 16px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.input-container input:focus {
  border-color: #059669;
  box-shadow: 0 0 0 2px rgba(5, 150, 105, 0.1);
}

.input-container button {
  background-color: #059669;
  color: white;
  padding: 8px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.input-container button:hover {
  background-color: #047857;
}

/* Scrollbar styling */
.chatbot-messages::-webkit-scrollbar {
  width: 6px;
}

.chatbot-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chatbot-messages::-webkit-scrollbar-thumb:hover {
  background: #666;
}
