.adc-container {
    width: 100%;
    max-width: 600px;
    height: 500px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: #f5f5f5;
    position: relative; /* Added for scroll containment */
    overflow: hidden; /* Added for scroll containment */
}

.adc-header {
    padding: 1rem;
    background: #25D366; /* WhatsApp green */
    color: white;
    border-radius: 8px 8px 0 0;
}

.adc-header h3 {
    margin: 0;
    font-size: 1.2rem;
}

.adc-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    scroll-behavior: smooth;
    position: relative; /* Added for scroll containment */
}

.adc-message {
    display: flex;
    margin-bottom: 0.5rem;
    opacity: 0;
    transform: translateY(20px) scale(0.95);
    transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.adc-message.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
}

.adc-bot {
    justify-content: flex-start;
}

.adc-user {
    justify-content: flex-end;
}

.adc-bubble {
    max-width: 70%;
    padding: 0.8rem;
    border-radius: 12px;
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    transform-origin: bottom;
}

.adc-bot .adc-bubble {
    background: white;
    border-bottom-right-radius: 4px;
}

.adc-user .adc-bubble {
    background: #DCF8C6; /* WhatsApp message green */
    color: #000000;
    border-bottom-left-radius: 4px;
}

.adc-time {
    display: block;
    font-size: 0.7rem;
    margin-top: 0.3rem;
    opacity: 0.7;
}

.adc-input {
    padding: 1rem;
    background: white;
    border-top: 1px solid #e0e0e0;
    display: flex;
    gap: 0.5rem;
    border-radius: 0 0 8px 8px;
}

.adc-input-field {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    font-size: 1rem;
}

.adc-send-btn {
    padding: 0.5rem 1rem;
    background: #25D366; /* WhatsApp green */
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.adc-send-btn:hover {
    background: #1fa855;
}

.adc-typing {
    display: flex;
    gap: 0.3rem;
    padding: 0.8rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    background: white;
    border-radius: 12px;
    width: fit-content;
    margin-bottom: 0.5rem;
}

.adc-typing.visible {
    opacity: 1;
}

.adc-typing-dot {
    width: 8px;
    height: 8px;
    background: #25D366; /* WhatsApp green */
    border-radius: 50%;
    animation: typing-animation 1.4s infinite ease-in-out;
}

.adc-typing-dot:nth-child(1) { animation-delay: 0.2s; }
.adc-typing-dot:nth-child(2) { animation-delay: 0.3s; }
.adc-typing-dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing-animation {
    0%, 100% { transform: translateY(0); opacity: 0.5; }
    50% { transform: translateY(-4px); opacity: 1; }
}

@media (max-width: 600px) {
    .adc-container {
        height: 100vh;
        border-radius: 0;
        border: none;
    }

    .adc-header {
        border-radius: 0;
    }

    .adc-bubble {
        max-width: 85%;
    }

    .adc-input {
        border-radius: 0;
    }
}