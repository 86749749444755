mlink {
    display: block;
    margin: 20px 10px;
    font-weight: 700;
    font-size: 22px;
}

.sb-link {    
    color: var(--color1);
    text-decoration: none;
    font-family: rubik;
    font-weight: 700;    
    margin-right: 4px;    
}

@media only screen and (max-width: 1400px) {
    .sb-link {    
        color: var(--color1);
        text-decoration: none;
        font-family: rubik;
        font-weight: 700;
        font-size: 1rem;
        margin-right: 2px;          
    }
}
.sb-link-active {
    color: var(--color2);
}

.sb-link:hover {
    text-decoration: none;
    color: var(--color2) ;
}

.sb-li {
    text-decoration: none;  
    margin: 7px 0;  
}

.sb-ul {    
    list-style: none;
    margin: 0;      
    display: flex;  
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline-start: 6px;
}

.icon1 {
    margin-left: 10px;
    margin-right: 6px;
}