
.products-container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* .products-search-container {
  width: 100%;
  min-width: 180px;
  max-width: 900px;
  padding: 2px;
  margin: auto;
  display: flex;
  flex-direction: row;
} */
.products-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-field {
  box-sizing: border-box;
  line-height: normal;
  padding: 5px 8px; /* Adjust padding to match other elements */
}

.search-field {
  width: 70%;
  margin: 0.5%;
}

.category-select {
  width: 30%;
  margin: 0.5%;
}

.add-product-button {
  padding: 2px;
  margin: 0.5%;
  margin-right: 3px;
  font-weight: bold;
  width: auto;
}

.search-field,
.category-select,
.add-product-button {
  height: 50px; /* Adjust as needed */
}

.add-product-section {
  margin-top: 20px;
}

.products-list-header {
  display: flex;
  color: var(--color1);
  flex-direction: row;
  margin-top: 4px;
  padding: 10px;
}

.header-cell {
  width: 20%;
  text-align: center;
}

.epm-list {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.epm-product-info {
  width: 30%;
}

.epm-product-category,
.epm-product-price {
  width: 20%;
  text-align: center;
}

.epm-product-availability {
  width: 10%;
  text-align: center;
}

.epm-modal {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  max-width: 500px;
}

.epm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;    
}

.epm-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;    
}

.epm-label {
  font-weight: bold;
}

.epm-category-section {
  display: flex;
  gap: 1rem;    
}

.epm-availability-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}


/* Paginaion CSS */
.prd-pg-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  margin-top: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.prd-pg-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prd-pg-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.prd-pg-button:not(:disabled):hover {
  background-color: #45a049;
}

.prd-pg-page-indicator {
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
}
