.settings-msg {
    padding: 2%;
    width: 90%;
    height: 200px;
}

.sm-template {
    padding: 0;
    height: 80px;
}
.sm-open-msg {
    padding: 0;
}
