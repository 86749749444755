
/* the following line is to solve issues from LastPass */
iframe#webpack-dev-server-client-overlay{display:none!important}

html, body {
  overflow-x: hidden;
}

.frame-pc {
  border: 2px black solid;
  width: 995px;  
    border: 0;
    -ms-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -o-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    /* transform: scale(0.5); */

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    /* transform-origin: 100% 0; */
    transform: 0.45,
}


:root {
    --color1: #324A4A;
    --color2: #20C8C8;
    --color3: #ECF0F0;
  }

@media only screen and (max-width: 800px){
    .only-pc {
        display: none !important;          
        width: 0;
    }
    .title-size {
      font-size: 16px;      
    }
    .content-div {
      max-width: "75%";
      min-width: "75%";
    }
}

.content-div {
  max-width: "75%";
  min-width: "75%";  
  margin-right: "5%"; 
}

@media only screen and (min-width: 800px){
    .only-mobile {
        display: none !important;
    }
    .title-size {
      font-size: 32px;
    }
}

.modal-header1 {
    justify-content: space-between;    
    display: flex;
    flex-direction: row;
  }

.modal-title1 {
    color: var(--color1);    
    display: flex;
    justify-content: space-between;
    margin: 0%;
    padding: 4%;
    border-bottom: 2px solid var(--color1);
  }

.label1 {
  color: var(--color1);
  margin-right: 6%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 600;
}

.fl {
  color: var(--color1);
  display: block;
    margin-right: 3%;
    font-weight: 500;
    margin-top: 3%;
    font-size: 1rem;
}
.fi {    
  width: 90%;
  border-radius: 10px;  
  border-color: rgb(214, 232, 245);
  margin: auto;
}


.sb {
  background-color: var(--color3);
}

.gs-list {
  display: flex;
  flex-direction: row;
  background-color: var(--color3);
  border-radius: 15px;
  margin-top: 4px;
  padding: 10px;
}

.gs-list:hover {
  background-color: var(--color2);
  cursor: pointer;      
}

.hover-pointer {}
.hover-pointer:hover {
  cursor: pointer;      
}

.clickable { }
.clickable:hover { 
  cursor: pointer;
}


.color-button {
  background: linear-gradient(45deg, #FF5733, #FF914D, #333333, #FFFFFF);
            border: none;
            color: white;
            padding: 10px 20px;
            cursor: pointer;
            width: 262px;
}

.ltr {
  direction: ltr;
}

.error-container {
  text-align: center;
  margin: auto;
  margin-top: 200px;

}
.error-msg {
  font-size: x-large;  
  color: var(--color1);
}