.obuttons {
    background-color: var(--color3);
    color: var(--color1);
    margin: 1%;    
    
}

.obuttons:hover {
    cursor: pointer;
    background-color: var(--color2);    
}
td {

}

.orders-refresh {
  margin-right: 10px;
  margin-bottom: 10px;
}

.orders-page-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 95%;
  }
  
  .orders-page-filter-container {
    display: flex;
    align-items: center; /* Ensures all items align vertically in the center */
    gap: 10px; /* Adds spacing between elements */
    margin-bottom: 15px;
  }
  .orders-page-filter-container label {
    margin-right: 10px; /* Adds spacing from the dropdown */
    font-size: 14px; /* Ensures a consistent font size */
    line-height: 1; /* Adjusts vertical spacing within the label */
  }
  
  .orders-page-filter-select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .orders-page-search-box {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
  }
  
  .orders-page-header {
    display: flex;
    flex-direction: row;
    color: var(--color1);
    font-weight: 700;
    margin-right: 2%;
    text-align: center;
  }
  
  .orders-page-header-item {
    flex: 1;
    text-align: center;
  }
  
  .orders-page-list {
    text-align: center;
  }
  
  .orders-page-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
  }
  
  .orders-page-pagination-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .orders-page-pagination-button:hover {
    background-color: #e0e0e0;
  }
  
  .orders-page-pagination-button.active {
    background-color: var(--color1);
    color: white;
    font-weight: bold;
  }
  