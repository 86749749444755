/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.dashboard-demo-inner-container {
  
  text-align: center;
}
.animated-demo-container {
  margin: 3%;
}
.welcome-app {
  font-family: system-ui, -apple-system, sans-serif;
  direction: rtl;
}

/* Navigation */
.welcome-navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.welcome-nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #059669;
}

.welcome-nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.welcome-nav-links a {
  color: #4b5563;
  text-decoration: none;
  transition: color 0.3s;
}

.welcome-nav-links a:hover {
  color: #059669;
}

.welcome-nav-cta-button {
  background-color: #059669;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.demo-chat:hover {
  background-color: #047857;
}

/* Hero section */
.welcome-hero {
  background: linear-gradient(to left, #059669, #065f46);
  color: white;
  padding: 8rem 1rem 4rem;
  margin-top: 4rem;
}

.welcome-hero-container {
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-hero-content {
  max-width: 600px;
}

.welcome-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.welcome-hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.welcome-hero-cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.welcome-hero-cta-note {
  font-size: 0.875rem;
  opacity: 0.9;
}

.welcome-hero-cta-button {
  background-color: white;
  color: #059669;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 9999px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}

.welcome-hero-cta-button:hover {
  background-color: #f3f4f6;
}

/* Features section */
.welcome-features {
  padding: 4rem 1rem;
  background-color: #f3f4f6;
}

.welcome-section-container {
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-features h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
}

.welcome-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.welcome-feature-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.welcome-feature-icon {
  font-size: 2.5rem;
  color: #059669;
  margin-bottom: 1rem;
  display: block;
}

.welcome-feature-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

#demo-chat {
  display: flex; 
  margin: auto;
  max-width: 1200px;
}
/* query media on demo-chat */
@media (max-width: 768px) {
  #demo-chat {
    flex-direction: column;    
  }
}

/* Contact section */
.welcome-contact {
  padding: 4rem 1rem;
  background-color: #f3f4f6;  
}
.welcome-form-titles-container {
  padding-right: 20%;
}
.welcome-contact-intro {
  /* text-align: center; */
  margin-bottom: 2rem;
  color: #4b5563;
}

.welcome-contact-form {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.welcome-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.welcome-form-group {
  margin-bottom: 1rem;
}

.welcome-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4b5563;
}

.welcome-form-group input,
.welcome-form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
}

.welcome-form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.welcome-submit-button {
  background-color: #059669;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-weight: bold;
}

.welcome-submit-button:hover {
  background-color: #047857;
}

.welcome-submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.welcome-status-message {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  text-align: center;
}

.welcome-status-message.welcome-success {
  background-color: #ecfdf5;
  color: #059669;
}

.welcome-status-message.welcome-error {
  background-color: #fef2f2;
  color: #dc2626;
}

/* Footer */
.welcome-footer {
  background-color: #1f2937;
  color: white;
  padding: 4rem 1rem 2rem;
}

.welcome-footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.welcome-footer-info h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.welcome-footer-info p {
  color: #9ca3af;
  line-height: 1.6;
}

.welcome-footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
}

.welcome-footer-section h4 {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.welcome-footer-section ul {
  list-style: none;
}

.welcome-footer-section ul li {
  margin-bottom: 0.5rem;
}

.welcome-footer-section ul li a {
  color: #9ca3af;
  text-decoration: none;
  transition: color 0.3s;
}

.welcome-footer-section ul li a:hover {
  color: white;
}

.welcome-footer-bottom {
  border-top: 1px solid #374151;
  padding-top: 2rem;
  text-align: center;
  color: #9ca3af;
}

/* Feature icons */
.welcome-feature-icon.robot::before {
  content: "🤖";
}

.welcome-feature-icon.cart::before {
  content: "🛒";
}

.welcome-feature-icon.clock::before {
  content: "⏰";
}

/* Mobile responsiveness */
@media (max-width: 768px) {

  .welcome-nav-links {
    display: none;
  }

  .welcome-hero h1 {
    font-size: 2rem;
    text-align: center;
  }

  .welcome-hero p {
    font-size: 1.125rem;
    text-align: center;
  }

  .welcome-feature-card {
    text-align: center;
  }

  .welcome-footer-content {
    text-align: center;
  }

  .welcome-footer-links {
    text-align: center;
  }

  .welcome-hero-cta {
    align-items: center;
    text-align: center;
  }

  .welcome-contact-intro {
    padding: 0 1rem;
  }
  .welcome-section-container h2{
    text-align: center;
  }
  .welcome-form-titles-container {
    padding-right: 0%;
  }
}
/* Focus states for better accessibility */
.welcome-form-group input:focus,
.welcome-form-group textarea:focus {
  outline: none;
  border-color: #059669;
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.1);
}

.welcome-submit-button:focus,
.welcome-nav-cta-button:focus,
.welcome-hero-cta-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.5);
}

/* Additional hover states */
.welcome-feature-card:hover {
  /* transform: translateY(-2px);
  transition: transform 0.3s ease; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-nav-login-button {
  background-color: transparent;
  color: #059669;
  padding: 0.5rem 1rem;
  border: 2px solid #059669;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;
}

.welcome-nav-login-button:hover {
  background-color: #059669;
  color: white;
}

.welcome-nav-login-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.3);
}

@media (max-width: 768px) {
  .welcome-nav-links .welcome-nav-login-button {
    display: block;
  }
}