.store-image-div {
    text-align: center;    
    min-width: 200px;    
}

.store-image {
    /* padding: 1rem;
    min-width: 200px;
    max-width: 250px;     */
}

.store-image-buttons {
    width: 30%;
    height: 10%;
    font-size: 20px;
 }

 .image-cropper {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 5%;
  }

  .image-cropper:hover {
    cursor: pointer;
  }