.categories-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #f7f9fc;
    border-radius: 8px;
}

.categories-title {
    font-size: 1.5em;
    color: #333;
    text-align: center;
    margin-bottom: 12px;
}

.add-category {
    align-self: center;        
    font-size: 1em;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 12px;
}

.add-category:hover {
    background-color: #0056b3;
}

.categories-list {
    list-style: none;
    margin-top: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.categories-li {
    padding: 10px;
    background-color: #e9ecef;
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.categories-li:hover {
    background-color: #ced4da;
}

/* Add/Edit Category Modals */
.modal-header h2 {
    font-size: 1.5em;    
    color: var(--color1);
    text-align: center;
}

.cat-modal-dialog div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}

.cat-input
.cat-textarea {
    width: 100%;
    padding: 8px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
}

button.update {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

button.cat-cancel {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

button.cat-delete {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}


/* Responsive design */
@media (max-width: 768px) {
    .categories-container, .cat-modal-dialog div {
        padding: 12px;
    }

    .categories-title {
        font-size: 1.2em;
    }

    .categories-li {
        font-size: 0.9em;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .categories-title {
        font-size: 1em;
    }

    .categories-li, .add-category {
        font-size: 0.85em;
        padding: 8px 12px;
    }

    button {
        font-size: 0.9em;
        padding: 8px;
    }
}
