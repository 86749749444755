.terms-container {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 2rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
}

.terms-content {
  max-width: 1000px;
  margin: 0 auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eee;
}

.header h1 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.header p {
  color: #7f8c8d;
  font-size: 1.2rem;
}

.main-content {
  direction: rtl;
  text-align: right;
}

.section {
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.section h2 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f0f0;
}

.section p {
  color: #34495e;
  margin-bottom: 1rem;
}

.highlight-box {
  background-color: #f8f9fa;
  border-right: 4px solid #3498db;
  padding: 1rem;
  margin: 1rem 0;
}

.warning-box {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
}

.list {
  padding-right: 1.5rem;
  margin: 1rem 0;
}

.list li {
  margin-bottom: 0.5rem;
}

.footer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 2px solid #eee;
  text-align: center;
  color: #7f8c8d;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .terms-content {
    padding: 1rem;
  }

  .header h1 {
    font-size: 2rem;
  }

  .section h2 {
    font-size: 1.3rem;
  }
}
