.msgs {
    background-color: var(--color3);
    border-radius: 15px;
    padding: 0.5% 5%;
    margin: 1%;
    color: var(--color1);
    display: flex;
}

.msgs:hover {
    background-color: var(--color2);
}